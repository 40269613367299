import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageLocale } from "@components/Shared/model/LanguageContent";
import { ContentSection } from "@ryerson/frontend.layout";
import { Video } from "@ryerson/frontend.media";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { LeadershipProfileSetupLanguage } from "./Content";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { renderRichText } from "@components/Shared/model/Contentful";
import { css } from "@emotion/react";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";

export type LeaderContentfulType = {
	credentials: any;
	leftColumn: any;
	rightColumn?: any;
	fullName: string;
	node_locale: "en-US" | "fr-CA" | "es-MX";
	picture: any;
	videoThumbnail: any;
	videoId: string;
	slug: string;
	title: string;
};

export type ContentfulNode = {
	node: LeaderContentfulType;
};

export type LeadershipLeader = {
	contentfulData: ContentfulNode[];
};

export type LeadershipProfileSetup = {
	experienceLabel: string;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const Divider = styled.hr`
	margin-top: 10px;
	margin-bottom: 0px;
	opacity: 0.1;
`;

const Left = styled.div`
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 660px);
`;

const Right = styled.div`
	display: inline-block;
	width: 560px;
	margin-left: 100px;
	height: 420px;
`;

const ColContainer = styled.div`
	display: inline-block;
	width: calc(50% - 20px);
	vertical-align: top;
`;

const Spacer = styled.div`
	display: block;
	height: 80px;
	width: 100%;
`;

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme, buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 100%;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, true, buttonSize)};
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				width: 100%;
				height: 100%;
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, false, buttonSize)};
			}
		`;
	}}
`;

const Profile: React.FC<LeadershipLeader> = ({ contentfulData }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const [languageContent, setLanguageContent] = React.useState<LeaderContentfulType>(
		contentfulData[0].node
	);
	React.useEffect(() => {
		contentfulData.forEach((data: ContentfulNode) => {
			if (LanguageLocale[data.node.node_locale] === language) setLanguageContent(data.node);
		});
	}, [language]);
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.tertiary.background,
								percentage: "60%",
							},
							{
								color: theme.colors.primary.background,
								percentage: "40%",
							},
						],
					}}
				>
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Left>
						<Typography
							variant="h1"
							css={css`
								font-size: 75px;
								margin-top: 21px;
								margin-bottom: 32px;
							`}
							type="tertiary"
						>
							{languageContent.fullName}
						</Typography>
						<Typography
							variant="div"
							type="tertiary"
							css={css`
								position: relative;
								display: block;
								width: 100%;
							`}
						>
							{languageContent.title}
						</Typography>
						<Divider
							css={css`
								color: ${theme.colors.primary.white};
							`}
						/>
					</Left>
					<Right>
						{languageContent.videoId && languageContent.videoId.length > 0 ? (
							<VideoWrapper theme={theme} buttonOrientation="bottom">
								<Video
									videoId={languageContent.videoId}
									imageUrl={languageContent.videoThumbnail.file.url}
									buttonOrientation="bottom"
								></Video>
							</VideoWrapper>
						) : (
							<img
								src={
									languageContent.videoThumbnail &&
									languageContent.videoThumbnail.file
										? languageContent.videoThumbnail.file.url
										: languageContent.picture.file.url
								}
								css={css`
									display: block;
									object-fit: cover;
									max-height: 100%;
									max-width: 100%;
								`}
							/>
						)}
					</Right>
				</ContentSection>
				<ContentSection type="primary" vPadding="0px">
					<ColContainer
						css={css`
							margin-right: 40px;
							margin-top: -140px;
						`}
					>
						{renderRichText(languageContent.leftColumn.raw, "primary")}
						{(!languageContent.rightColumn || languageContent.rightColumn === null) && (
							<>
								{languageContent.credentials &&
									languageContent.credentials.credentials &&
									languageContent.credentials.credentials.length > 0 && (
										<>
											<Typography
												type="primary"
												variant="h4"
												css={css`
													margin-bottom: 21px;
												`}
											>
												{
													LeadershipProfileSetupLanguage[language]
														.experienceLabel
												}
											</Typography>
											<Typography type="primary" variant="p">
												{languageContent.credentials.credentials}
											</Typography>
										</>
									)}
							</>
						)}
					</ColContainer>
					<ColContainer>
						{languageContent.rightColumn &&
							languageContent.rightColumn !== null &&
							languageContent.rightColumn.raw && (
								<>
									{renderRichText(languageContent.rightColumn.raw, "primary")}
									<>
										{languageContent.credentials &&
											languageContent.credentials.credentials &&
											languageContent.credentials.credentials.length > 0 && (
												<>
													<Typography
														type="primary"
														variant="h4"
														css={css`
															margin-bottom: 21px;
														`}
													>
														{
															LeadershipProfileSetupLanguage[language]
																.experienceLabel
														}
													</Typography>
													<Typography type="primary" variant="p">
														{languageContent.credentials.credentials}
													</Typography>
												</>
											)}
									</>
								</>
							)}
					</ColContainer>
					<Spacer />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="30px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 11px;
							margin-bottom: 24px;
						`}
					>
						{languageContent.fullName}
					</Typography>
					<Typography
						variant="div"
						type="tertiary"
						weight="bold"
						size="lg"
						css={css`
							position: relative;
							display: block;
							margin-bottom: 5px;
							width: 100%;
							padding-right: 70px;
							box-sizing: border-box;
						`}
					>
						{languageContent.title}
					</Typography>
					{languageContent.videoId && languageContent.videoId.length > 0 ? (
						<VideoWrapper theme={theme} buttonOrientation="top-right">
							<Video
								videoId={languageContent.videoId}
								imageUrl={languageContent.videoThumbnail.file.url}
								buttonOrientation="top-right"
							></Video>
						</VideoWrapper>
					) : (
						<img
							src={
								languageContent.videoThumbnail &&
								languageContent.videoThumbnail.file
									? languageContent.videoThumbnail.file.url
									: languageContent.picture.file.url
							}
							css={css`
								display: block;
								max-height: 100%;
								max-width: 100%;
							`}
						/>
					)}
				</ContentSection>
				<ContentSection type="primary" vPadding="30px">
					<Typography size="md">
						{renderRichText(languageContent.leftColumn.raw, "primary")}
					</Typography>
					{languageContent.rightColumn &&
						languageContent.rightColumn !== null &&
						languageContent.rightColumn.raw && (
							<Typography size="md">
								{renderRichText(languageContent.rightColumn.raw, "primary")}
							</Typography>
						)}
					{languageContent.credentials &&
						languageContent.credentials.credentials &&
						languageContent.credentials.credentials.length > 0 && (
							<>
								<Typography
									type="primary"
									variant="h3"
									css={css`
										margin-bottom: 0px;
									`}
								>
									{LeadershipProfileSetupLanguage[language].experienceLabel}
								</Typography>
								<Typography type="primary" size="md" variant="p">
									{languageContent.credentials.credentials}
								</Typography>
							</>
						)}
				</ContentSection>
			</Media>
		</>
	);
};
export default Profile;
